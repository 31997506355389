<template>
  <header class="header">
    <div class="left-wrap">
      <img class="logo" :src="logo" alt="">
    </div>
    <div class="right-wrap">
      <nav class="nav">
        <div class="nav-item active">首页</div>
        <div class="nav-item">行业方案</div>
        <div class="nav-item">广告形式</div>
        <div class="nav-item">行业资源</div>
        <div class="nav-item">行业范畴</div>
        <div class="nav-item">关于我们</div>
      </nav>
      <div class="contact-us">联系我们</div>
      <div class="language">中文</div>
    </div>
  </header>
</template>

<script>
import imgSrc from '@/assets/images/logo.png';

export default {
  data() {
    return {
      logo: imgSrc,
    };
  },

};
</script>

<style lang="scss" scoped>
.header {
  // background: blue;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  height: 120px;
  .left-wrap {
    .logo {
      width: 180px;
    }

  }

  .right-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    .nav{
      height: 100%;
      display: flex;
      // flex-grow: 1;
    }

    .nav-item {
      color: rgb(0, 0, 0);
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      transition: color 0.4s ease 0s;
      cursor: pointer;
      flex-grow: 1;
      &:hover{
        color: rgb(110,110,110);
      }
      &.active{
        color: rgb(255,191,35);
      }

    }

    .contact-us {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      background:  rgb(255, 191, 35);
      width: 112px;
      height: 40px;
      margin-left: 40px;
      margin-right: 40px;
    }

    .language {}

  }

}
</style>
